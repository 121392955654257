<template>
  <v-btn
    :disabled="disabled"
    :loading="loading"
    :color="color"
    :class="buttonClasses"
    @click="sendInvoice">
    {{ buttonText }}
  </v-btn>
</template>

<script>
import Request from '../../helpers/request'

export default {
  mixins: [Request],
  props: {
    color: {
      type: String,
      default: 'secondary'
    },
    invoiceId: {
      type: Number,
      default: null
    },
    invoiceType: {
      type: String,
      default: 'normal'
    },
    isCombined: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: 'Send Invoice'
    },
    buttonClasses: {
      type: String,
      default: ''
    },
    afterAction: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async sendInvoice () {
      this.loading = true
      if (this.invoiceType === 'combined') {
        await this.request('POST', `/combined-invoices/${this.invoiceId}/send`, {})
      } else {
        await this.request('POST', `/reverse-invoices/${this.invoiceId}/send`, {})
      }
      this.loading = false
      this.afterAction()
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
