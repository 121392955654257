<template>
  <v-form
    ref="invoiceForm"
    v-model="valid"
    lazy-validation>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            heading="Reverse invoice information"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <rotate-square2 v-if="loading" />
              <v-container
                v-else
                grid-list-xl>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.number"
                      :rules="[v => Boolean(v) || 'Please provide a number']"
                      readonly
                      type="text"
                      label="Invoice number"
                    />
                  </v-flex>
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.poNumber"
                      :rules="[v => Boolean(v) || 'Please provide a poNumber']"
                      readonly
                      type="text"
                      label="PO number"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.driverName"
                      :rules="[v => Boolean(v) || 'Please provide a driver name']"
                      readonly
                      type="text"
                      label="Driver name"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.passengerName"
                      :rules="[v => Boolean(v) || 'Please provide a customer name']"
                      readonly
                      type="text"
                      label="Customer name"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    xs12>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.address"
                      :rules="[v => Boolean(v) || 'Please provide a address']"
                      readonly
                      type="text"
                      label="Address"
                    />
                  </v-flex> -->

                  <!-- <v-flex
                    xs12>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.startLocation.address"
                      readonly
                      type="text"
                      label="Start location"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    xs12>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.endLocation.address"
                      readonly
                      type="text"
                      label="End location"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.declaredDuration.time"
                      readonly
                      type="text"
                      label="Declared duration"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.declaredWaitingTime.time"
                      readonly
                      type="text"
                      label="Declared waiting time"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.carBrand"
                      readonly
                      type="text"
                      label="Car brand"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.carLicensePlate"
                      readonly
                      type="text"
                      label="Car license plate"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.carModel"
                      readonly
                      type="text"
                      label="Car model"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.carTransmissionType"
                      readonly
                      type="text"
                      label="Car transmission type"
                    />
                  </v-flex> -->
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.ride.id"
                      readonly
                      type="text"
                      label="Ride id"
                    />
                  </v-flex>
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.rideCost"
                      readonly
                      type="text"
                      label="Ride cost"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.waitingTimeCost"
                      readonly
                      type="text"
                      label="Waiting time cost"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.publicTransportCost"
                      readonly
                      type="text"
                      label="Public transport cost"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.platformCost"
                      readonly
                      type="text"
                      label="Platform cost"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.insuranceCost"
                      readonly
                      type="text"
                      label="Insurance cost"
                    />
                  </v-flex> -->
                  <!-- <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.extraCost"
                      readonly
                      type="text"
                      label="Extra cost"
                    />
                  </v-flex> -->
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.vat"
                      readonly
                      type="text"
                      label="VAT"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.totalExclVAT"
                      readonly
                      type="text"
                      label="Total exclude vat"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.totalInclVAT"
                      readonly
                      type="text"
                      label="Total include vat"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.sentAt"
                      readonly
                      type="datetime-local"
                      label="Sent at"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.invoicedAt"
                      readonly
                      type="datetime-local"
                      label="Invoiced At"
                    />
                  </v-flex>
                </v-layout>
                <send-invoice-button
                  color="secondary"
                  button-classes='text-white'
                  invoice-type='reverse'
                  :invoice-id="invoice.id"
                  :after-action="requestData" />
              </v-container>
            </div>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { TEXT_FIELD_MAX_LENGTH } from '../../../../constants/common'
import CrudTable from '../../../../modules/admin/CrudTable.vue'
import Request from '../../../../helpers/request'
import SendInvoiceButton from 'Components/Buttons/SendInvoiceButton.vue'

export default {
  components: { CrudTable, SendInvoiceButton },
  mixins: [Request],
  data () {
    return {
      editInvoicedAtLoader: false,
      valid: true,
      loading: true,
      data: [],
      invoice: {},
      TEXT_FIELD_MAX_LENGTH,
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      }
    }
  },
  beforeMount () {
    this.requestData()
  },
  methods: {
    requestData () {
      this.request('GET', `/reverse-invoices/${this.$route.params.id}`, {}, ({ data }) => {
        this.invoice = {
          ...data,
          startLocation: data.startLocation || {},
          endLocation: data.endLocation || {},
          declaredDuration: data.declaredDuration || {},
          declaredWaitingTime: data.declaredWaitingTime || {}
        }
      }, (loader) => { this.loading = loader })
    },
    saveFunction () {
      if (this.$refs.invoiceForm.validate()) {
        let body = {
          data: {
            'title': this.invoice.title,
            'description': this.invoice.description,
            'icon': this.invoice.icon
          }
        }

        this.request('PATCH', `/reverse-invoices/${this.invoice.id}`, body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Invoice has been changed'
          })

          this.$router.push('/tables/reverse-invoices')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
    img {
      width: 124px;
      height: 124px;
      background-color: white;
      border: 1px solid #DDD;
      padding: 5px;
    }
}
</style>
